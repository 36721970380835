@import "../../styles/variables";

.slider-image-box {
    width: 50%;
    height: 100%;

    border-radius: 10px;
    overflow: hidden;

    &:hover {
        box-shadow: var(--box-shadow);
    }

    .slider-img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
            transition: all 0.3s ease;
        }
    }
}

.next-btn,
.prev-btn {
    position: absolute;
    top: 40%;
    font-size: 1rem;
    padding: 0 .5rem;
    background-color: whitesmoke;
    border: none;

    &:hover {
        cursor: pointer;
        background-color: gray;
    }
}

.next-btn {
    right: 0;
}

.prev-btn {
    left: 0;
}