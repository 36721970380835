@import "../../styles/variables";

.user-detail-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .user-detail-card-top-box {
        height: 90px;

        display: flex;
        flex-direction: row;
        gap: 1rem;

        padding: 1rem;
        background-color: white;
        border-radius: var(--button-radius);
        box-shadow: var(--box-shadow);

        overflow: hidden;

        .image-box {
            width: calc(90px - 1rem);
            height: calc(90px - 2rem);
            overflow: hidden;
            border-radius: var(--button-radius);
            box-shadow: var(--box-shadow);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }

            .image-detail {
                display: none;


            }

            &:hover .image-detail {
                display: block;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9999;
                width: 300px;
                height: 300px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                border-radius: var(--button-radius);
                transition: all 0.3s ease;

            }
        }

        .user-detail-name-role {
            width: 100%;
            color: black;

            .user-role {
                width: 100%;

                .user-role-change-box {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: .5rem;

                    .select-box {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: .3rem;

                        select {

                            padding: .3rem;
                        }
                    }

                    select,
                    button {
                        background-color: #535B6A;
                        color: white;

                    }
                }

            }
        }
    }

    .user-detail-card-bottom-box {
        flex: 1;

        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: 1rem;
        padding-bottom: .5rem;

        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        gap: 1rem;
        overflow: auto;

    }

}