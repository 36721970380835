.banner-card-container {
  border: var(--grey-border);
  border-radius: var(--button-radius);
  height: 100%;
  overflow: hidden;
  animation: slideLeftRight 0.7s infinite ease-in-out;
}
.banner-card-container .img-container {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
}
.banner-card-container .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

@keyframes slideLeftRight {
  0% {
    border-width: 0px;
  }
  50% {
    border-width: 2px;
  }
  100% {
    border-width: 3px;
  }
}/*# sourceMappingURL=bannerCard.css.map */