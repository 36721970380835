@import "../../styles/variables";

.banner-add-form-container {
    max-width: 400px;
    margin: 0 auto;

    .form-group {
        margin-bottom: 15px;

        label {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .change-image-label {
            background-color: rgb(0, 119, 255);
            color: white;
            width: max-content;
            font-size: .8rem;
            font-weight: normal;
            padding: .5rem;
            border-radius: 5px;
            cursor: pointer;
        }

        input[type='file'] {
            display: none;
        }

        .image-box {
            position: relative;
            width: 150px;
            width: 100%;
            height: 160px;
            border: 1px solid #ccc;
            overflow: hidden;
            border-radius: 5px;
            margin-bottom: .5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        select {
            background-color: rgb(55, 136, 241);
            color: white;
        }
    }

    input[type='number'],
    input[type='text'] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .date-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        .form-group {
            width: 50%;

            input {
                width: 100%;
            }
        }
    }

    .form-buttons {
        display: flex;
        justify-content: space-between;

        button {
            padding: 10px 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }

        button[type='submit'] {
            background-color: #007bff;
            color: #fff;
        }

        button[type='button'] {
            background-color: #ccc;
            color: #000;
        }
    }
}