@import '../../styles/variables';

.upload-image-container {
    width: 75%;
    height: 15vh;

    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--box-shadow);


    @media (min-width:1290px) {
        height: 20vh;

    }

    img {

        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    button {
        position: absolute;
        background-color: #535B6A;
        color: #fff;
        border: none;
        box-shadow: var(--box-shadow);
        padding: .3rem .5rem;
        border-radius: 10px;

        &:hover {
            cursor: pointer;
            background-color: #535b6ad7;
        }
    }

    .delete-img-button {
        top: 5px;
        right: 5px;
    }

    .new-img-button {
        left: 5px;
        top: 5px;
    }

    .next-img-button {
        right: 5px;
        bottom: 5px;
    }

}

.upload-img-btn {
    width: 75%;

    button {
        width: 100%;
        background-color: var(--select-background-color);
        color: var(--select-color);
        box-shadow: var(--box-shadow);
        padding: .3rem;
        border-radius: 10px;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }
}