@import "../../styles/variables";


.sidebar-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow: hidden;

    // @include light-box;
    background: var(--box-background-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--box-radius);

    ul {
        list-style-type: none;
        padding: 1rem;
        overflow: auto;

        li {
            margin: 1rem 0;

            &:hover {
                cursor: pointer;
            }


            a {
                display: flex;
                flex-direction: row;
                align-items: center;

                text-decoration: none;
                font-weight: 500;
                font-size: 17px;
                color: var(--text-color);

            }

            .active-link {
                color: var(--text-hover-color);
                font-weight: bold;

                fill: var(--text-hover-color);
            }



            &:hover a {
                font-weight: bold;
                color: var(--text-hover-color);
            }

            /* Icons */
            svg,
            i {
                // fill: #535B6A;
                fill: var(--text-color);
                width: 20px;
                height: 20px;
                margin-right: .5rem;


                /* transparan ikonlara gölge vermek için */
                // filter: drop-shadow(10px 15px 12px rgba(166, 180, 200, 0.9))


            }

            .fa-certificate,
            .fa-table-list,
            .fa-headset {
                font-size: 1.3rem;
            }

            .fa-house-circle-check {
                font-size: 1.2rem;
                margin-left: -.1rem;
            }

            .fa-question {
                margin-right: .5rem;
                padding-left: .2rem;
                font-size: 1.3rem;
                font-weight: bold;
            }

            &:hover a svg {
                font-weight: bold;
                color: var(--text-hover-color);
                fill: var(--text-hover-color);

            }
        }

        .notification-li {
            position: relative;

            .notification-badge {
                position: absolute;
                top: -11px;
                left: -13px;
                background-color: rgb(230, 57, 57);
                color: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.541);
                font-size: .7rem;
                border-radius: 5px;
                padding: .1rem .3rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .sidebar-logo {
        // background-color: red;
        text-align: center;
        padding: .5rem;
        overflow: hidden;
    }

}