@charset "UTF-8";
:root {
  --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
  --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
  --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
  --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-color-light: #535B6A;
  --text-color-dark: #C8C8C8;
  --text-hover-color-light: #344250;
  --text-hover-color-dark: #FBFBFB;
  --table-select-color-light: #FBFBFB;
  --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);
  --pagination-color-dark: #344250;
  --pagination-color-light: #FBFBFB;
  --button-background-color-light: #fbfbfbe4;
  --button-background-color-dark: #535b6abe;
  --button-background-hover-color-dark: #535B6A;
  --button-background-hover-color-light: #FBFBFB;
  --button-color-light: #FBFBFB;
  --button-color-dark: #535B6A;
  --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
  --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);
  --button-radius: 10px;
  --box-radius: 20px;
  --logo-radius: 10px;
  --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
  --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);
  --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;
  --select-background-color-dark: #535B6A;
  --select-background-color-light: #FBFBFB;
  --select-color-dark: #535B6A;
  --select-color-light: #FBFBFB;
  --scroll-color: #888888;
}

.light-theme-styles {
  --box-background-color: var(--box-background-color-light);
  --box-shadow: var(--box-shadow-light);
  --text-color: var(--text-color-light);
  --text-hover-color: var(--text-hover-color-light);
  --text-shadow: var(--text-shadow-light);
  --button-background-color: var(--button-background-color-light);
  --button-background-hover-color: var(--button-background-hover-color-light);
  --button-color: var(--button-color-dark);
  --button-box-shadow: var(var(--button-box-shadow-light));
  --table-select-color: var(--table-select-color-light);
  --input-shadow: var(--input-shadow-light);
  --select-background-color: var(--select-background-color-dark);
  --select-color: var(--select-color-light);
  --grey-border: var(--grey-border-dark);
}

.dark-theme-styles {
  --box-background-color: var(--box-background-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --text-color: var(--text-color-dark);
  --text-hover-color: var(--text-hover-color-dark);
  --text-shadow: var(--text-shadow-dark);
  --button-background-color: var(--button-background-color-dark);
  --button-background-hover-color: var(--button-background-hover-color-dark);
  --button-color: var(--button-color-light);
  --button-box-shadow: var(var(--button-box-shadow-dark));
  --table-select-color: var(--table-select-color-dark);
  --input-shadow: var(--input-shadow-dark);
  --select-background-color: var(--select-background-color-light);
  --select-color: var(--select-color-dark);
  --grey-border: var(--grey-border-light);
}

.table-null-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
}
@media (max-width: 1400px) {
  .table-null-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .table-null-text {
    font-size: 0.8rem;
  }
}

.disabled-box {
  pointer-events: none;
  opacity: 0.6;
}

/* Scroll stil */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.standart-table-container {
  width: 100%;
  height: 100%;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  overflow: auto;
  position: relative;
  /* Pagination tablodaki grid düzen ayarı */
}
.standart-table-container .table-box {
  padding: 0 1rem;
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  /* Tablodaki elemanı seçerken oluşan animasyonlar */
}
.standart-table-container .table-box table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.standart-table-container .table-box table th,
.standart-table-container .table-box table td {
  text-align: center;
  font-size: 0.9rem;
  padding: 0.5rem 0.8rem;
  white-space: nowrap;
  /* Metnin otomatik olarak satıra sığdırılmasını engeller */
  max-width: 150px;
  overflow: hidden;
  /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
  text-overflow: ellipsis;
  /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */
}
.standart-table-container .table-box table th .image-box,
.standart-table-container .table-box table td .image-box {
  width: 50px;
  height: 50px;
  border-radius: var(--logo-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.standart-table-container .table-box table th .image-box img,
.standart-table-container .table-box table td .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.standart-table-container .table-box table thead {
  width: 100%;
}
.standart-table-container .table-box table thead tr {
  background: var(--box-background-color) !important;
  color: var(--text-hover-color) !important;
  border-radius: 0.3rem;
  position: sticky;
  top: 0;
  height: 50px;
}
.standart-table-container .table-box table tbody tr {
  color: var(--text-color);
}
.standart-table-container .table-box table tbody tr:hover {
  cursor: pointer;
  background: var(--table-select-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--box-radius);
}
.standart-table-container .table-box table tbody tr:hover td:first-child {
  border-radius: 10px 0 0 10px;
}
.standart-table-container .table-box table tbody tr:hover td:last-child {
  border-radius: 0 10px 10px 0;
}
.standart-table-container .table-box .table-row-select {
  background: var(--table-select-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--button-radius);
}
.standart-table-container .table-box .table-row-select td {
  color: var(--text-hover-color);
  font-weight: 700;
}
.standart-table-container .table-box .table-row-select td:first-child {
  border-radius: 10px 0 0 10px;
}
.standart-table-container .table-box .table-row-select td:last-child {
  border-radius: 0 10px 10px 0;
}
.standart-table-container .table-box .table-row-select td:first-child {
  border-radius: 10px 0 0 10px;
}
.standart-table-container .table-box .table-row-select td:last-child {
  border-radius: 0 10px 10px 0;
}
.standart-table-container .pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  padding: 0 1rem;
}/*# sourceMappingURL=standartTable.css.map */