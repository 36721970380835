.company-detail-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .company-detail-card-top-box {
        height: 90px;

        display: flex;
        flex-direction: row;
        gap: 1rem;

        padding: 1rem;
        background-color: white;
        border-radius: var(--button-radius);
        box-shadow: var(--box-shadow);

        overflow: hidden;

        .image-box {
            width: calc(90px - 2rem);
            height: calc(90px - 2rem);
            overflow: hidden;
            border-radius: var(--button-radius);
            box-shadow: var(--box-shadow);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .user-detail-name-role {
            color: black;
        }
    }

    .company-detail-card-bottom-box {
        flex: 1;

        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: 1rem;
        padding-bottom: .5rem;

        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        gap: 1rem;

        overflow: auto;

    }

}