@import '../../styles/variables';

.tender-detail-offer-form {
    width: 100%;
    height: calc(var(--main-height) - 50px - 2rem);
    overflow: auto;

    .input-standart {
        margin-bottom: .4rem;

        label,
        input,
        select,
        textarea,
        button {
            font-size: .7rem;
            outline: none;
        }

        label {
            color: var(--text-color);
        }

        input[type="date"] {
            background-color: var(--select-background-color);
            color: var(--select-color);
        }


        select {
            width: 75%;
            height: 25px;
            padding: .1rem;
            text-align: center;
        }

        textarea {
            width: 75%;

            background-color: #FBFBFB;
            // color: #535B6A;
            font-weight: 700;
            box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
            border-radius: 10px;
            border: none;
            padding: 5px 10px;

            outline-color: #535B6A;
        }

    }

    button {
        color: var(--select-color);
        font-weight: bold;
    }

    .documan,
    .image-align,
    .note-align,
    .description-align {
        align-items: flex-start;
    }

    .documan {

        .documan-detail {
            height: 3vh;
        }
    }

    .image-align {
        height: auto;


        .upload-img-btn {
            width: 75%;

            button {
                width: 100%;
            }
        }

        .image-container {
            width: 75%;
            height: 15vh;
            height: auto;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .description-align {

        textarea {
            height: 6vh;
            padding: .5rem;
        }
    }

    .note-align {

        textarea {
            height: 5vh;
            padding: .5rem;
        }
    }

}