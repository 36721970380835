@import '../../../../styles/variables';

.button-white-style {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    color: var(--button-color);
    box-shadow: var(--box-shadow);

    border-radius: .5rem;
    border: none;
    padding: .5rem;
    font-weight: 700;
    position: relative;
    width: 100%;
   
    i{
        font-size:1.2rem ;
    }

    svg{
        fill:$text-color-dark;
        width: 20px;
        height: 20px;
    }

    span{
        padding-left: .3rem;
    }


}

.button-white-active{

    &:hover {
        cursor: pointer;
        // background-color: #ffffff;
        // color: rgb(0, 0, 0);
    } 
}

.button-white-disabled{
    opacity: .5;
    cursor: no-drop;
}
