.banner-panel-container {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .banner-panel-edit-box {
        height: 60px;

        @media (max-width:768px) {
            height: max-content;
            
        }
    }

    .banner-panel-main {
        flex: 1;
        overflow: auto;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 1rem;

        padding: 1rem;

        .banner-card {
            display: flex;
            flex-direction: column;
            height: max-content;
            border-radius: var(--button-radius);
            box-shadow: var(--box-shadow);
            background-color: white;
            overflow: hidden;

            .image-box {
                height: 170px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .detail-box {
                padding: 1rem;
                padding-bottom: .3rem;

                h4 {
                    margin-bottom: 0.5rem;
                }

                .banner-status {
                    // display: inline-block;
                    float: right;
                    padding: 4px 8px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                }

                .banner-status-orange {
                    background-color: orange;
                    color: #fff;
                }

                .banner-status-green {
                    background-color: green;
                    color: #fff;
                }

                .banner-status-red {
                    background-color: red;
                    color: #fff;
                }


                small {
                    color: #777;
                }
            }

            .edit-box {
                padding: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 0.5rem;

                .status-buttons {
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                }

            }
        }
    }

    .banner-panel-pagination {
        height: 40px;
        display: flex;
        align-items: center;
    }

}

.confirm-modal-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .modal-actions-button{
        align-self: flex-end;

        display: flex;
        gap: .5rem;

    }
}