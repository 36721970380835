@import '../../../styles/variables';

// .select-table-main {
//     height: 100%;

//     display: grid;
//     grid-template-rows: 1fr;
//     grid-template-areas:

//         "tablecontainer"
//     ;
//     gap: .5rem;

//     .table-container {
//         grid-area: tablecontainer;

//         border: 1px solid #a3a3a3;
//         border-radius: 10px;
//         padding: 1rem;

//         display: grid;
//         grid-template-rows: 1fr 50px;
//         grid-template-areas:
//             "tablebox"
//             "pagination"
//         ;
//         gap: 1rem;

//         overflow: hidden;


//         .table-box {

//             grid-area: tablebox;

//             width: 100%;
//             position: relative;

//             overflow: auto;

//             table {
//                 overflow: auto;
//                 width: 98%;
//                 border-collapse: collapse;
//                 table-layout: auto;
//                 position: absolute;


//                 th,
//                 td {
//                     width: max-content;
//                     text-align: center;
//                     font-size: .9rem;
//                     padding: .3rem .8rem;

//                     white-space: nowrap;
//                     /* Metnin otomatik olarak satıra sığdırılmasını engeller */
//                     // max-width: 50px;
//                     overflow: hidden;
//                     /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
//                     text-overflow: ellipsis;
//                     /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */


//                     img {
//                         @include image-cover;
//                         // filter: drop-shadow(10px 15px 12px rgba(166, 180, 200, 0.9))
//                     }


//                 }

//                 thead {
//                     width: 100%;
//                     position: relative;

//                     tr {
//                         // background: linear-gradient(359.84deg,#E2E4EA 6.56% , #EAECF1 97.86%);
//                         // background-color: #eaebf1;
//                         background-color: var(--background-color);
//                         color: var(--text-hover-color);

//                         // box-shadow: $box-shadow;
//                         border-radius: .3rem;
//                         position: sticky;
//                         top: 0;
//                         // z-index: 1;
//                         height: 50px;

//                     }
//                 }

//                 tbody {
//                     tr {


//                         color: var(--text-color);

//                         &:hover {
//                             cursor: pointer;
//                             // background-color: #FBFBFB;
//                             // box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.25), 10px 10px 12px -6px rgba(166, 180, 200, 0.25);
//                             // border-radius: 10px;

//                             background: var(--table-select-color);
//                             box-shadow: var(--box-shadow);
//                             border-radius: var(--box-radius);
//                         }

//                         &:hover td:first-child {
//                             border-radius: 10px 0 0 10px;
//                         }

//                         &:hover td:last-child {
//                             border-radius: 0 10px 10px 0;
//                         }

//                     }
//                 }

//             }
//         }

//         /* Tablodaki elemanı seçerken oluşan animasyonlar */

//         .table-row-select {
//             background: var(--table-select-color);
//             box-shadow: var(--box-shadow);
//             border-radius: var(--button-radius);

//             td {
//                 color: var(--text-hover-color);
//                 font-weight: 700;

//                 &:first-child {
//                     border-radius: 10px 0 0 10px;
//                 }

//                 &:last-child {
//                     border-radius: 0 10px 10px 0;
//                 }
//             }

//         }

//         .table-row-select td:first-child {
//             border-radius: 10px 0 0 10px;
//         }

//         .table-row-select td:last-child {
//             border-radius: 0 10px 10px 0;
//         }

//         /* Pagination tablodaki grid düzen ayarı */
//         .pagination {
//             grid-area: pagination;
//             // background-color: black;
//         }
//     }

// }
.select-table-container {
    width: 100%;
    height: 100%;
    border: var(--grey-border);
    border-radius: var(--button-radius);
    overflow: auto;
    position: relative;
    
    .table-box {
        padding: 0 1rem;
        width: 100%;
        height: calc(100% - 50px);
        overflow: auto;

        position: absolute;
        top: 0;
        left: 0;


        table {
            width: 100%;
            border-collapse: collapse;
            overflow: auto;

            th,
            td {
                text-align: center;
                font-size: .9rem;
                padding: .5rem .8rem;

                white-space: nowrap;
                /* Metnin otomatik olarak satıra sığdırılmasını engeller */
                max-width: 150px;
                overflow: hidden;
                /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
                text-overflow: ellipsis;
                /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */
                // height: 50px;

                .image-box {
                    width: 50px;
                    height: 50px;
                    border-radius: var(--logo-radius);
                    box-shadow: var(--box-shadow);
                    overflow: hidden;

                    img {
                        // filter: drop-shadow(10px 15px 12px rgba(166, 180, 200, 0.9))
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }


            }

            thead {
                width: 100%;
                // position: relative;

                tr {
                    // background-color: #eaebf1;
                    background: var(--box-background-color);
                    color: var(--text-hover-color);
                    // box-shadow: $box-shadow;
                    border-radius: .3rem;
                    position: sticky;
                    top: 0;
                    // z-index: 1;
                    height: 50px;


                }
            }

            tbody {
                tr {
                    color: var(--text-color);
                    // max-height: 50px;
                    // background-color: red;


                    &:hover {
                        cursor: pointer;
                        // background-color: #FBFBFB;
                        // box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.25), 10px 10px 12px -6px rgba(166, 180, 200, 0.25);
                        // border-radius: 10px;
                        background: var(--table-select-color);
                        box-shadow: var(--box-shadow);
                        border-radius: var(--box-radius);
                    }

                    &:hover td:first-child {
                        border-radius: 10px 0 0 10px;
                    }

                    &:hover td:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                }
            }

        }

        /* Tablodaki elemanı seçerken oluşan animasyonlar */
        .table-row-select {
            background: var(--table-select-color);
            box-shadow: var(--box-shadow);
            border-radius: var(--button-radius);

            td {
                color: var(--text-hover-color);
                font-weight: 700;

                &:first-child {
                    border-radius: 10px 0 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }
            }

        }

        .table-row-select td:first-child {
            border-radius: 10px 0 0 10px;
        }

        .table-row-select td:last-child {
            border-radius: 0 10px 10px 0;
        }

    }


    /* Pagination tablodaki grid düzen ayarı */
    .pagination {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50px;
        padding: 0 1rem;
    }

}
