@import '../../../styles/variables';

// .medium-table-box {
//     width: 100%;
//     height: 100%;

//     position: relative;

//     overflow: auto;

//     table {
//         overflow: auto;
//         width: 98%;
//         border-collapse: collapse;
//         table-layout: auto;
//         position: absolute;


//         th,
//         td {
//             text-align: center;
//             white-space: nowrap;
//             /* Metnin otomatik olarak satıra sığdırılmasını engeller */
//             max-width: 150px;
//             overflow: hidden;
//             /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
//             text-overflow: ellipsis;
//             /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */

//             font-size: .6rem;
//             padding: .5rem;

//         }

//         thead {
//             position: relative;
//             width: 100%;

//             tr {
//                 // background-color: #eaebf1;
//                 background: var(--box-background-color);
//                 z-index: 2;
//                 color: var(--text-hover-color);

//                 // box-shadow: $box-shadow;
//                 border-radius: .3rem;
//                 border-radius: var(--button-radius);
//                 position: sticky;
//                 top: 0;

//                 th {

//                     padding: .3rem;
//                 }
//             }
//         }

//         tbody {
//             padding-top: 1rem;
//             background-color: red;

//             tr {
//                 color: var(--text-color);


//                 &:hover {
//                     cursor: pointer;
//                     // background-color: #FBFBFB;
//                     // box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.25), 10px 10px 12px -6px rgba(166, 180, 200, 0.25);
//                     // border-radius: 10px;
//                     background: var(--table-select-color);
//                     box-shadow: var(--box-shadow);
//                     border-radius: var(--box-radius);
//                 }

//                 &:hover td:first-child {
//                     border-radius: 10px 0 0 10px;
//                 }

//                 &:hover td:last-child {
//                     border-radius: 0 10px 10px 0;
//                 }


//             }
//         }

//     }

//     /* Tablodaki elemanı seçerken oluşan animasyonlar */
//     .table-row-select {
//         background: var(--table-select-color);
//         box-shadow: var(--box-shadow);
//         border-radius: var(--button-radius);

//         td {
//             color: var(--text-hover-color);
//             font-weight: 700;

//             &:first-child {
//                 border-radius: 10px 0 0 10px;
//             }

//             &:last-child {
//                 border-radius: 0 10px 10px 0;
//             }
//         }

//     }

//     .table-row-select td:first-child {
//         border-radius: 10px 0 0 10px;
//     }

//     .table-row-select td:last-child {
//         border-radius: 0 10px 10px 0;
//     }
// }


.medium-table-box {
    width: 100%;
    height: 100%;
    // border: var(--grey-border);
    // border-radius: var(--button-radius);
    overflow: auto;
    position: relative;


    .table-box {
        padding: 0 .3rem;
        width: 100%;
        height: 100%;
        overflow: auto;

        position: absolute;
        top: 0;
        left: 0;


        table {
            width: 100%;
            border-collapse: collapse;
            overflow: auto;

            th,
            td {
                font-size: .8rem;

                @media (max-width:1400px) {
                    font-size: .7rem;
                }
            
                @media (max-width:768px) {
                    font-size: .6rem;
                }

                text-align: center;
                // padding: .5rem .8rem;

                white-space: nowrap;
                /* Metnin otomatik olarak satıra sığdırılmasını engeller */
                max-width: 150px;
                overflow: hidden;
                /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
                text-overflow: ellipsis;
                /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */
                // height: 50px;

                .image-box {
                    width: 50px;
                    height: 50px;
                    border-radius: var(--logo-radius);
                    box-shadow: var(--box-shadow);
                    overflow: hidden;

                    img {
                        // filter: drop-shadow(10px 15px 12px rgba(166, 180, 200, 0.9))
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }


            }

            thead {
                width: 100%;
                // position: relative;

                tr {
                    // background-color: #eaebf1;
                    background: var(--box-background-color);
                    color: var(--text-hover-color);
                    // box-shadow: $box-shadow;
                    border-radius: .3rem;
                    position: sticky;
                    top: 0;
                    // z-index: 1;
                    height: 30px;


                    th {

                        padding: .3rem;
                    }

                }
            }

            tbody {
                tr {
                    color: var(--text-color);


                    &:hover {
                        cursor: pointer;
                        // background-color: #FBFBFB;
                        // box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.25), 10px 10px 12px -6px rgba(166, 180, 200, 0.25);
                        // border-radius: 10px;
                        background: var(--table-select-color);
                        box-shadow: var(--box-shadow);
                        border-radius: var(--box-radius);
                    }

                    &:hover td:first-child {
                        border-radius: 10px 0 0 10px;
                    }

                    &:hover td:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                    td {
                        padding: .5rem;
                    }

                }
            }

        }

        /* Tablodaki elemanı seçerken oluşan animasyonlar */
        .table-row-select {
            background: var(--table-select-color);
            box-shadow: var(--box-shadow);
            border-radius: var(--button-radius);

            td {
                color: var(--text-hover-color);
                font-weight: 700;

                &:first-child {
                    border-radius: 10px 0 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }
            }

        }

        .table-row-select td:first-child {
            border-radius: 10px 0 0 10px;
        }

        .table-row-select td:last-child {
            border-radius: 0 10px 10px 0;
        }

    }


}