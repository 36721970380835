@import "../../styles/variables";

.navbar-container {
    width: 100%;
    height: 100%;
    // @include light-box;
    background: var(--box-background-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--box-radius);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-right: 1rem;



    .navbar-logo {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;


        h2 {
            padding-top: .5rem;
        }

        /* MenuIcon */
        svg {
            fill: var(--text-color);
            stroke: var(--text-color);
            filter: drop-shadow(var(--box-shadow));

            &:hover {
                // fill: white;
                // stroke: white;
                cursor: pointer;
            }
        }

        img{
            &:hover{
                cursor: pointer;
                opacity: 0.9;
            }
        }
    }

    .navbar-right-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;


        .navbar-profile {
            // z-index: 1;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .profile-box {
                position: relative;
                width: 100%;
                min-width: 200px;

                @media (max-width:768px) {
                    min-width: max-content;
                }

                cursor: pointer;
                user-select: none;
                // border-radius: 10px;


                .profile {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-radius: 10px 10px 0 0;
                    padding: .2rem .5rem;

                    background-color: #535B6A;
                    color: rgb(248, 248, 248);

                    @media (max-width:768px) {
                        border-radius: 50%;
                        height: max-content;
                        width: max-content;
                        padding: .2rem;
                        padding: 0 0;
                    }

                    .profile-image {
                        width: 30px;
                        height: 30px;
                        margin-right: .5rem;
                        border-radius: 10px;
                        overflow: hidden;


                        @media (max-width:768px) {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            margin-right: 0;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;

                            &:hover {
                                fill: black;
                                stroke: black;
                            }
                        }
                    }

                    .profile-name {

                        @media (max-width:768px) {
                            display: none;
                        }
                    }
                }

                .dropdown-none-profile {

                    border-radius: 10px;
                    transition: border-radius 0.5s ease-in-out;

                    @media (max-width:768px) {
                        border-radius: 50%;
                        height: max-content;
                        width: max-content;
                        padding: .2rem;
                    }
                }

                .dropdown-active-profile {
                    background-color: $box-bg-color-dark;
                    transition: background-color 0.5s ease-in-out;

                    @media (max-width:768px) {
                        background-color: #535B6A;
                        border-radius: 50% 50% 0 0;
                        height: max-content;
                        width: max-content;
                        padding: .2rem;
                    }
                }

                .profile-dropdown-box {
                    // border:2px solid red;
                    background-color: #535B6A;
                    color: white;
                    position: absolute;
                    top: 35px;
                    right: 0;


                    width: 100%;
                    min-width: 200px;
                    height: auto;


                    border-radius: 0 0 10px 10px;
                    z-index: 2;

                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.3s ease-in-out;

                    @media (max-width:768px) {
                        top: 40px;
                        right: -.05rem;
                        border-radius: 10px 0 10px 10px;
                    }

                    ul {
                        list-style-type: none;
                        border-radius: 0 0 10px 10px;

                        li {
                            // border: 1px solid red;
                            padding: .6rem;
                            text-align: center;


                            &:hover {
                                background-color: $box-bg-color-dark;
                            }

                            // &:last-child {
                            //     border-radius: 0 0 10px 10px;
                            // }

                        }

                        .dropdown-last-li {
                            border-radius: 0 0 10px 10px;
                        }

                        a {
                            text-decoration: none;
                            color: inherit;
                        }

                        .theme-li {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: .2rem;

                            span {
                                // margin-left: -1.5rem;
                                font-size: .6rem;
                                color: rgba(227, 227, 227, 0.663);
                            }

                            .theme-buttons {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                gap: .5rem;

                                button {
                                    background: transparent;
                                    border: none;
                                    background-color: rgb(237, 237, 237);
                                    color: rgba(0, 0, 0, 0.403);
                                    padding: 0 1rem;
                                    border-radius: var(--button-radius);

                                    &:hover {
                                        color: rgb(0, 0, 0);
                                        box-shadow: 0 0 5px rgb(14, 14, 14);
                                        cursor: pointer;
                                    }
                                }

                                .select-theme {
                                    box-shadow: 0 0 5px turquoise;
                                    color: rgb(0, 0, 0);
                                }
                            }
                        }

                    }
                }

                /* PROFİL DROPDOWN MENÜ AYARLARI */
                .profile-dropdown-box-open {
                    max-height: max-content;

                    @media (max-width:768px) {
                        // border-radius: 50% 50% 0px 0px;
                        margin-right: .05rem;
                    }
                }

                .profile-dropdown-box-close {
                    background-color: #535B6A;
                }

            }



        }

    }


}