@charset "UTF-8";
:root {
  --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
  --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
  --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
  --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-color-light: #535B6A;
  --text-color-dark: #C8C8C8;
  --text-hover-color-light: #344250;
  --text-hover-color-dark: #FBFBFB;
  --table-select-color-light: #FBFBFB;
  --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);
  --pagination-color-dark: #344250;
  --pagination-color-light: #FBFBFB;
  --button-background-color-light: #fbfbfbe4;
  --button-background-color-dark: #535b6abe;
  --button-background-hover-color-dark: #535B6A;
  --button-background-hover-color-light: #FBFBFB;
  --button-color-light: #FBFBFB;
  --button-color-dark: #535B6A;
  --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
  --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);
  --button-radius: 10px;
  --box-radius: 20px;
  --logo-radius: 10px;
  --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
  --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);
  --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;
  --select-background-color-dark: #535B6A;
  --select-background-color-light: #FBFBFB;
  --select-color-dark: #535B6A;
  --select-color-light: #FBFBFB;
  --scroll-color: #888888;
}

.light-theme-styles {
  --box-background-color: var(--box-background-color-light);
  --box-shadow: var(--box-shadow-light);
  --text-color: var(--text-color-light);
  --text-hover-color: var(--text-hover-color-light);
  --text-shadow: var(--text-shadow-light);
  --button-background-color: var(--button-background-color-light);
  --button-background-hover-color: var(--button-background-hover-color-light);
  --button-color: var(--button-color-dark);
  --button-box-shadow: var(var(--button-box-shadow-light));
  --table-select-color: var(--table-select-color-light);
  --input-shadow: var(--input-shadow-light);
  --select-background-color: var(--select-background-color-dark);
  --select-color: var(--select-color-light);
  --grey-border: var(--grey-border-dark);
}

.dark-theme-styles {
  --box-background-color: var(--box-background-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --text-color: var(--text-color-dark);
  --text-hover-color: var(--text-hover-color-dark);
  --text-shadow: var(--text-shadow-dark);
  --button-background-color: var(--button-background-color-dark);
  --button-background-hover-color: var(--button-background-hover-color-dark);
  --button-color: var(--button-color-light);
  --button-box-shadow: var(var(--button-box-shadow-dark));
  --table-select-color: var(--table-select-color-dark);
  --input-shadow: var(--input-shadow-dark);
  --select-background-color: var(--select-background-color-light);
  --select-color: var(--select-color-dark);
  --grey-border: var(--grey-border-light);
}

.table-null-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
}
@media (max-width: 1400px) {
  .table-null-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .table-null-text {
    font-size: 0.8rem;
  }
}

.disabled-box {
  pointer-events: none;
  opacity: 0.6;
}

/* Scroll stil */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* React select css */
.basic-multi-select {
  font-size: 14px;
  width: 75%;
}
.basic-multi-select .select__control {
  border-radius: 10px;
  border-color: #ccc;
  background-color: #535B6A;
  box-shadow: none;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}
.basic-multi-select .select__control:hover, .basic-multi-select .select__control:focus {
  border-color: #999;
  cursor: pointer;
}
.basic-multi-select .select__control--is-focused {
  border-color: #999;
  box-shadow: none;
}
.basic-multi-select .select__control .select__input input[type=text] {
  color: #fff;
  /* Yazı rengi beyaz olarak ayarlandı */
}
.basic-multi-select .select__control .select__placeholder {
  color: #fff;
  /* Placeholder rengi beyaz olarak ayarlandı */
}
.basic-multi-select .select__menu {
  background-color: #535B6A;
  font-size: 14px;
  z-index: 2;
  overflow: auto;
}
.basic-multi-select .select__menu .select__option {
  color: #f8f8f8;
  color: #333;
  cursor: pointer;
  font-weight: 400;
  padding: 8px 12px;
}
.basic-multi-select .select__menu .select__option--is-selected {
  background-color: #e6e6e6;
  color: #333;
}
.basic-multi-select .select__menu .select__option:hover, .basic-multi-select .select__menu .select__option:focus {
  background-color: #ddd;
  color: #ffffff;
  color: #333;
}

/* adet unit-type input */
.input-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.input-two .input-standart {
  width: 100%;
}
.input-two .input-standart .input-select-box {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.tender-edit {
  height: var(--main-height);
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.tender-edit .display-none {
  display: none;
}
.tender-edit .disabled {
  opacity: 0.5;
  pointer-events: none;
}
.tender-edit .step-one {
  height: var(--main-height);
  width: 400px;
}
@media (max-width: 768px) {
  .tender-edit .step-one {
    width: 100%;
  }
}
.tender-edit .step-one .step-one-container {
  width: 400px;
  height: var(--main-height);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}
@media (max-width: 768px) {
  .tender-edit .step-one .step-one-container {
    width: 100%;
  }
}
.tender-edit .step-one .step-one-container .step-one-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  height: 50px;
}
.tender-edit .step-one .step-one-container .step-one-top .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.tender-edit .step-one .step-one-container .step-one-top .title-box .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.tender-edit .step-one .step-one-container .step-one-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.3rem;
  overflow: auto;
}
.tender-edit .step-one .step-one-container .step-one-form-container label {
  min-width: 123px;
  font-size: 0.7rem;
  color: var(--text-color);
}
.tender-edit .step-one .step-one-container .step-one-form-container input,
.tender-edit .step-one .step-one-container .step-one-form-container .input-div input,
.tender-edit .step-one .step-one-container .step-one-form-container textarea,
.tender-edit .step-one .step-one-container .step-one-form-container button,
.tender-edit .step-one .step-one-container .step-one-form-container select {
  flex: 1;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
}
.tender-edit .step-one .step-one-container .step-one-form-container select {
  cursor: pointer;
  background-color: var(--select-background-color);
  color: var(--select-color);
  border: none;
  outline: none;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  padding: 0.2rem 0.5rem;
  flex: 1;
}
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart .input-select-box {
  flex: 1;
}
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart .input-select-box input,
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart .input-select-box select {
  width: 50%;
}
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart .input-price {
  flex: 1;
}
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart .input-price input {
  padding: 5px 10px;
  padding-left: 2rem;
}
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart .basic-multi-select {
  flex: 1;
  font-size: 0.7rem;
}
.tender-edit .step-one .step-one-container .step-one-form-container .input-standart textarea {
  background: white;
  outline: none;
  border: none;
  box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  border-radius: 10px;
  flex: 1;
}
.tender-edit .step-one .step-one-container .step-one-form-container .documan {
  align-items: flex-start;
}
.tender-edit .step-one .step-one-container .step-one-form-container .documan .upload-img-btn {
  width: 75%;
  flex: 1;
}
.tender-edit .step-one .step-one-container .step-one-form-container .documan .upload-img-btn button {
  width: 100%;
}
.tender-edit .step-one .step-one-container .step-one-form-container .documan .documan-container,
.tender-edit .step-one .step-one-container .step-one-form-container .documan .upload-image-container {
  width: 75%;
  flex: 1;
}
.tender-edit .step-one .step-one-container .step-one-form-container input[type=date] {
  background-color: var(--select-background-color);
  color: var(--select-color);
}
.tender-edit .step-one .step-one-container .step-one-form-container .note-indent {
  text-indent: 7px;
}
.tender-edit .step-two {
  height: var(--main-height);
  flex: 1;
}
.tender-edit .step-two .step-two-container {
  flex: 1;
  height: var(--main-height);
  padding: 1rem;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.tender-edit .step-two .step-two-container .step-two-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  height: 50px;
}
.tender-edit .step-two .step-two-container .step-two-top .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.tender-edit .step-two .step-two-container .step-two-top .title-box .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.tender-edit .step-two .step-two-container .supplier-table {
  flex: 1;
}
@media screen and (max-width: 1028px) {
  .tender-edit {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .tender-edit .step-one {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
  }
  .tender-edit .step-one .step-one-container {
    width: 100%;
    position: relative;
  }
}/*# sourceMappingURL=tenderEdit.css.map */