.homepage-container {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow: auto;
    padding-bottom: .1rem;

    .homepage-charts,
    .homepage-detail-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: .5rem;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 0.5rem;

        .card {
            height: calc(20vh - .5rem);
        }

    }

    .homepage-tables {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 0.5rem;

        @media (min-width:1700px) {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }

        @media (min-width:1700px) {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }

        .table {
            height: calc(var(--main-height) - 40vh - .5rem);
            

            @media (max-width:400px) {
                width: 300px;
            }
        }
    }

}